import logo from './logo.svg';
import './App.css';
import { Fragment } from 'react';
import PictureRecognition from "./PictureRecognition/PictureRecognition.js"
import Navbar from './Navbar/Navbar.js';
import Home from './Home/Home.js';
import TextRecognition from './TextReognition/TextRecognition.js';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Link, Outlet, Navigate } from "react-router-dom";
function App() {

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />}>
        <Route index element={<Home />} />
        <Route path='/picture-recognition' element={<PictureRecognition />} />
        <Route path='//text-recogntion' element = {<TextRecognition />}/>
      </Route>
    )
  );

  return (
    <Fragment>
      <RouterProvider router={router} />
    </Fragment>
  );
}

export default App;

const Root = () => {
  return (
    <Fragment>
      <Navbar />
      <div >
        <Outlet />
      </div>
    </Fragment>
  );
};
