import classes from './Navbar.module.css'
import { useNavigate } from 'react-router-dom';
function Navbar(){
    const navigate = useNavigate()
    const kloudrLogo = require('../assets/kloudrlogo.png')

    const homeRedirect = () => {
        navigate('/')
    } 

    return(
        <nav className={classes['navbar--layout']}>
            <div className={classes['navbar--div']}>
                <img src = {kloudrLogo} onClick={homeRedirect} />
                <p>AI Demo</p>
            </div>
            
        </nav>
    )
}

export default Navbar;