import classes from "./PictureRecognition.module.css"
import { useEffect, useState } from "react"
import { BlobServiceClient } from "@azure/storage-blob"
import axios from "axios";

function PictureRecognition() {
    const [image, setImage] = useState();
    const [imageURL, setImageURL] = useState();
    const [result, setResult] = useState([]);
    const [AWS, setAWS] = useState([]);
    const [Azure, setAzure] = useState([])


    const handleSubmit = async (event) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append('image', image);     
    
        try {
          const response = await axios.post('https://facilitrdemo-backend.azurewebsites.net/upload', formData, {
            headers: {
                'Access-Control-Allow-Headers': 'Content-Type',
                'Content-Type': 'multipart/form-data',
                'Access-Control-Allow-Origin' : '*',
                'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
                'ngrok-skip-browser-warning': 'true'
            },
          });
          console.log(response)
          if (response.status === 200){
            console.log('ok')
            console.log('Image uploaded successfully:', response.data);
            setResult(response.data.result.split('/'))
            setAWS(result[0].split(';'))
            setAzure(result[1].split(';'))
          }
        } catch (error) {
          console.error('Error uploading image:', error);
        }
      };

    useEffect(() => 
        {if(result[0]){
            console.log('im here')
            setAWS(result[0].split(';'))
            setAzure(result[1].split(';'))
        }
        if (image) {
            const url = URL.createObjectURL(image);
            setImageURL(url);

            return () => {
                URL.revokeObjectURL(url);
            };
        }
        
    }, [image,result[0]]);

    return (
        <div className={classes["demo--main--div"]}>
            <h1>AI Picture Recognition</h1>
            <div className={classes['flex--div']}>
                <div className={classes['input--div']}>
                    <input type="file" onChange={e => setImage(e.target.files[0])} accept="image/*"/>
                    <img src={imageURL} alt="Upload Image! "/>
                </div>
                <div className={classes['sbm--btn--div']}>
                     <button type = "submit" onClick={handleSubmit}>Submit</button> 
                </div>
                <div className={classes['result--div']}>
                    <div className={classes['returned--img-div']}>
                        <h3>Azure Result</h3>
                        {AWS ? AWS.map(results => {
                            return (
                                <div>
                                    {results}
                                </div>    
                            )
                        }) : <p>No results found!</p>}
                    </div>
                    <span className="span"></span>
                    <div>
                        <h3>AWS Result</h3>
                        {Azure ? Azure.map(results => {
                            return (
                                <div>
                                    {results}
                                </div>    
                            )
                        }

                        ) : <p>No Results Found!</p>}
                    </div>
                </div>
            </div>           
        </div>
    );
}

export default PictureRecognition;
