// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Navbar_navbar--layout__R0\\+-H{
    height : 7vh;
    width : 100%;
    background-color: #f7f7f7;
    border-bottom: 1px solid #28a8af;
}

.Navbar_navbar--div__TnCKV{
    height : 100%;
    width : 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.Navbar_navbar--div__TnCKV img{
    height : 100%;
    cursor : pointer;
}

.Navbar_navbar--div__TnCKV p{
    margin-right : 2%;
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/Navbar/Navbar.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,yBAAyB;IACzB,gCAAgC;AACpC;;AAEA;IACI,aAAa;IACb,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".navbar--layout{\r\n    height : 7vh;\r\n    width : 100%;\r\n    background-color: #f7f7f7;\r\n    border-bottom: 1px solid #28a8af;\r\n}\r\n\r\n.navbar--div{\r\n    height : 100%;\r\n    width : 100%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-between;\r\n    align-items: center;\r\n}\r\n\r\n.navbar--div img{\r\n    height : 100%;\r\n    cursor : pointer;\r\n}\r\n\r\n.navbar--div p{\r\n    margin-right : 2%;\r\n    font-size: 20px;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar--layout": `Navbar_navbar--layout__R0+-H`,
	"navbar--div": `Navbar_navbar--div__TnCKV`
};
export default ___CSS_LOADER_EXPORT___;
