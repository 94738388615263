import classes from './Home.module.css'
import { useNavigate } from 'react-router-dom';
function Home(){
    const navigate = useNavigate()
    const backgroudImg = require ('../assets/MicrosoftTeams-image (3).png')
    const pictureRecognitionRedirect = () => {
        navigate('/picture-recognition')
    }

    const textRecognitionRedirect = () => {
        navigate('/text-recogntion')
    }

    return(
        <div className={classes['home--main--div']}>
            <img src={backgroudImg} />
            <div className={classes['home--layout']}>
                <div className = {classes['picture--recognition--card']} onClick={pictureRecognitionRedirect}>
                    <h3>Picture Recognition Demo</h3>
                    <p>AI picture recognition empowers businesses to automatically identify and classify visual content in images and videos, aiding in tasks like product recognition, content moderation, and customer engagement.</p>
                </div>
                <div className= {classes['text--recognition--card']} onClick={textRecognitionRedirect}>
                    <h3>Text Recognition Demo</h3>
                    <p>AI text recognition helps businesses extract valuable information from printed or handwritten text, improving efficiency in data entry, document processing, and content analysis.</p>
                </div>
            </div>
        </div>
    )
}

export default Home;