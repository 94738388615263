// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Home_home--main--div__ZN87g{
    height : 93vh;
    width : 100%;
    overflow: hidden;
}

.Home_home--main--div__ZN87g img{
    position:fixed;
    z-index: -1;
    height: 100%;
    width : 100%;
}

body::-webkit-scrollbar{
    display: none;
}

.Home_home--layout__JMEbp{
    height : 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    color : #222222;
    margin : 5% 0 0 2%;
}

.Home_picture--recognition--card__XELfs{
    background-color: #ffffff;
    border-radius: 3%;
    height : 40%;
    width :20%;
    transition: 0.5s ease-out;
    text-align: center;
    align-items: center;
    cursor: pointer;
}

.Home_picture--recognition--card__XELfs:hover{
    border : 3px solid #28a8df;
    transform: scale(1.15);
}

.Home_picture--recognition--card__XELfs p{
    margin-top : 50px;
}

.Home_text--recognition--card__5aeAV{
    border : 1px solid #28a8df;
    background-color: #ffffff;
    border-radius: 3%;
    height : 40%;
    width :20%;
    text-align: center;
    transition: 0.5s ease-out;
    cursor: pointer;
}

.Home_text--recognition--card__5aeAV:hover{
    border : 3px solid #28a8df;
    transform: scale(1.15);
}

.Home_text--recognition--card__5aeAV p{
    margin-top : 40px;
}
`, "",{"version":3,"sources":["webpack://./src/Home/Home.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,YAAY;IACZ,gBAAgB;AACpB;;AAEA;IACI,cAAc;IACd,WAAW;IACX,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,aAAa;IACb,mBAAmB;IACnB,6BAA6B;IAC7B,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,UAAU;IACV,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;IAC1B,yBAAyB;IACzB,iBAAiB;IACjB,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,0BAA0B;IAC1B,sBAAsB;AAC1B;;AAEA;IACI,iBAAiB;AACrB","sourcesContent":[".home--main--div{\r\n    height : 93vh;\r\n    width : 100%;\r\n    overflow: hidden;\r\n}\r\n\r\n.home--main--div img{\r\n    position:fixed;\r\n    z-index: -1;\r\n    height: 100%;\r\n    width : 100%;\r\n}\r\n\r\nbody::-webkit-scrollbar{\r\n    display: none;\r\n}\r\n\r\n.home--layout{\r\n    height : 100%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n    color : #222222;\r\n    margin : 5% 0 0 2%;\r\n}\r\n\r\n.picture--recognition--card{\r\n    background-color: #ffffff;\r\n    border-radius: 3%;\r\n    height : 40%;\r\n    width :20%;\r\n    transition: 0.5s ease-out;\r\n    text-align: center;\r\n    align-items: center;\r\n    cursor: pointer;\r\n}\r\n\r\n.picture--recognition--card:hover{\r\n    border : 3px solid #28a8df;\r\n    transform: scale(1.15);\r\n}\r\n\r\n.picture--recognition--card p{\r\n    margin-top : 50px;\r\n}\r\n\r\n.text--recognition--card{\r\n    border : 1px solid #28a8df;\r\n    background-color: #ffffff;\r\n    border-radius: 3%;\r\n    height : 40%;\r\n    width :20%;\r\n    text-align: center;\r\n    transition: 0.5s ease-out;\r\n    cursor: pointer;\r\n}\r\n\r\n.text--recognition--card:hover{\r\n    border : 3px solid #28a8df;\r\n    transform: scale(1.15);\r\n}\r\n\r\n.text--recognition--card p{\r\n    margin-top : 40px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"home--main--div": `Home_home--main--div__ZN87g`,
	"home--layout": `Home_home--layout__JMEbp`,
	"picture--recognition--card": `Home_picture--recognition--card__XELfs`,
	"text--recognition--card": `Home_text--recognition--card__5aeAV`
};
export default ___CSS_LOADER_EXPORT___;
