// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextRecognition_demo--main--div__C3yNA{
    text-align: center;
    height : 100%;
    background-color: #ffffff;
    color : #222222;
}

.TextRecognition_flex--div__8VBsD{
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    margin-top: 1%;
}

.TextRecognition_input--div__2yiWd{
    display: flex;
    flex-direction: column;
    text-align: center;
}

.TextRecognition_input--div__2yiWd img{
    width: 800px;
    height: 600px ;
    border : 1px solid black;
}

.TextRecognition_result--div__s5YmV{
    margin-top : 2%;
    height : 90vh;
    display: flex;
    width : 100%;   
    flex-direction: row;
    justify-content: space-around;
}

span{
    border : 1px solid black;
    height : 100%;
}

.TextRecognition_sbm--btn--div__k1y7h{
    margin-top: 2%;
}
`, "",{"version":3,"sources":["webpack://./src/TextReognition/TextRecognition.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;IAClB,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,cAAc;IACd,wBAAwB;AAC5B;;AAEA;IACI,eAAe;IACf,aAAa;IACb,aAAa;IACb,YAAY;IACZ,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,wBAAwB;IACxB,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".demo--main--div{\r\n    text-align: center;\r\n    height : 100%;\r\n    background-color: #ffffff;\r\n    color : #222222;\r\n}\r\n\r\n.flex--div{\r\n    display: flex;\r\n    flex-direction: column;\r\n    text-align: center;\r\n    align-items: center;\r\n    margin-top: 1%;\r\n}\r\n\r\n.input--div{\r\n    display: flex;\r\n    flex-direction: column;\r\n    text-align: center;\r\n}\r\n\r\n.input--div img{\r\n    width: 800px;\r\n    height: 600px ;\r\n    border : 1px solid black;\r\n}\r\n\r\n.result--div{\r\n    margin-top : 2%;\r\n    height : 90vh;\r\n    display: flex;\r\n    width : 100%;   \r\n    flex-direction: row;\r\n    justify-content: space-around;\r\n}\r\n\r\nspan{\r\n    border : 1px solid black;\r\n    height : 100%;\r\n}\r\n\r\n.sbm--btn--div{\r\n    margin-top: 2%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"demo--main--div": `TextRecognition_demo--main--div__C3yNA`,
	"flex--div": `TextRecognition_flex--div__8VBsD`,
	"input--div": `TextRecognition_input--div__2yiWd`,
	"result--div": `TextRecognition_result--div__s5YmV`,
	"sbm--btn--div": `TextRecognition_sbm--btn--div__k1y7h`
};
export default ___CSS_LOADER_EXPORT___;
